import React from 'react';
import PropTypes from 'prop-types';
import Header from '../components/header';

import './layout.css';

const Layout = ({ children }) => {
  return (
    <div className="min-w-370 bg-blue-50">
      <Header className="absolute z-50" />
      <main>{children}</main>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
