import { Link } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const Header = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      strapiLogo {
        logo {
          childImageSharp {
            fixed(quality: 90, height: 44) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        logoWithTextDark {
          childImageSharp {
            fixed(quality: 90, height: 53) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    }
  `);

  const logos = [
    data.strapiLogo.logo.childImageSharp.fixed,
    {
      ...data.strapiLogo.logoWithTextDark.childImageSharp.fixed,
      media: '(min-width: 640px)',
    },
  ];

  return (
    <header class={'w-full px-6 text-blue-100 ' + className}>
      <div class="container flex flex-wrap items-center justify-between py-5 mx-auto max-w-7xl">
        <Link to="https://peachinsurance.net">
          <Img fixed={logos}></Img>
        </Link>
        <div class="relative flex flex-row">
          <nav class="flex flex-wrap items-center px-0 pb-0">
            <Link
              to="https://peachinsurance.net"
              className="mr-6 leading-6 text-blue-100 hover:text-blue-200"
              activeClassName="text-blue-200 hover:text-blue-300"
            >
              Home
            </Link>
            <Link
              to="/articles"
              className="leading-6 text-blue-100 hover:text-blue-200"
              activeClassName="text-blue-200 hover:text-blue-300"
            >
              Articles
            </Link>
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;
